
#heading {
    text-transform: uppercase;
    color: #673AB7;
    font-weight: normal
}

#msform {
    text-align: center;
    position: relative;
    margin-top: 20px
}

#msform fieldset {
    background: white;
    border: 0 none;
    border-radius: 0.5rem;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding-bottom: 20px;
    position: relative
}

.form-card {
    text-align: left
}

#msform fieldset:not(:first-of-type) {
    display: none
}

#msform input,
#msform textarea {
    padding: 12px 20px 12px 20px;
    border: 1px solid #ccc;
    border-radius: 6px;
    margin-bottom: 25px;
    margin-top: 2px;
    width: 100%;
    box-sizing: border-box;
    color: #2C3E50;
    font-size: 16px;
    letter-spacing: 1px
}

#msform input:focus,
#msform textarea:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid #20CA6F;
    outline-width: 0
}

#msform .action-button {
    width: 100px;
    background: #20CA6F;
    font-weight: bold;
    color: white;
    border: 0 none;
    border-radius: 28px;
    cursor: pointer;
    padding: 10px 5px;
    margin: 10px 0px 10px 5px;
    float: right
}

#msform .action-button:hover,
#msform .action-button:focus {
    background-color: #20CA6F
}

#msform .action-button-previous {
    width: 100px;
    background: #20CA6F;
    font-weight: bold;
    color: white;
    border: 0 none;
    border-radius: 28px;
    cursor: pointer;
    padding: 10px 5px;
    margin: 10px 5px 10px 0px;
    float: right
}

#msform .action-button-previous:hover,
#msform .action-button-previous:focus {
    background-color: #20CA6F
}

.card {
    z-index: 0;
    border: none;
    position: relative
}

.fs-title {
    font-size: 25px;
    color: #20CA6F;
    margin-bottom: 15px;
    font-weight: normal;
    text-align: left
}

.purple-text {
    color: #20CA6F;
    font-weight: normal
}

.steps {
    font-size: 25px;
    color: gray;
    margin-bottom: 10px;
    font-weight: normal;
    text-align: right
}

.fieldlabels {
    color: gray;
    text-align: left
}

#progressbar {
    margin-bottom: 30px;
    overflow: hidden;
    color: lightgrey
}

#progressbar .active {
    color: #20CA6F
}

#progressbar li {
    list-style-type: none;
    font-size: 15px;
    width: 25%;
    float: left;
    position: relative;
    font-weight: 400
}

#progressbar #account:before {
    content: "\f13e"
}

#progressbar #personal:before {
    content: "\f007"
}

#progressbar #payment:before {
    content: "\f030"
}

#progressbar #confirm:before {
    content: "\f00c"
}

#progressbar li:before {
    width: 50px;
    height: 50px;
    line-height: 45px;
    display: block;
    font-size: 20px;
    color: #ffffff;
    background: lightgray;
    border-radius: 50%;
    margin: 0 auto 10px auto;
    padding: 2px
}

#progressbar li:after {
    content: '';
    width: 100%;
    height: 2px;
    background: lightgray;
    position: absolute;
    left: 0;
    top: 25px;
    z-index: -1
}

#progressbar li.active:before,
#progressbar li.active:after {
    background: #20CA6F
}

.progress {
    height: 20px
}

.progress-bar {
    background-color: #20CA6F
}

.fit-image {
    width: 100%;
    object-fit: cover
}

::placeholder{
    color: rgba(128, 128, 128, 0.582) !important;
}

/* radio button start */
.btn-wrapper {
    height: 58px;
    width: 600.8px;
    margin-bottom: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .wrapper-last {
    margin-top: 12px;
  }
  
  .filler {
    margin-top: 42px;
    margin-bottom: 42px;
    font-size: 28px;
    color: #4b4b4b;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .filler .desc {
    font-size: 20px;
    color: #737373;
  }
  
  .duolingo-btn {
    font-family: "DIN Next Rounded LT Pro Medium";
    display: flex;
    align-items: center;
    justify-content: center;
    width: 600.8px;
    height: 58px;
    font-size: 20px;
    color: #20CA6F !important;
    border-radius: 16px;
    border: 2px solid #e5e5e5;
    border-bottom: 6px solid #e5e5e5;
    margin-top: 12px;
    padding: 12px 16px;
    line-height: 1.4;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .duolingo-btn:active {
    border-bottom: 2px solid #e5e5e5;
    transform: translateY(4px);
  }
  
  .btn-choice {
    border: 2px solid #e5e5e5;
    color: #afafaf;
    border-radius: 8px;
    width: 30px;
    height: 30px;
    margin-left: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .btn-text {
    width: 100%;
    text-align: start;
    font-size: 19px;
    color: #4b4b4b;
    letter-spacing: 0.5px;
  }
  
  .button-19 {
    appearance: button;
    background-color: #50c707;
    border: solid transparent;
    border-radius: 16px;
    border-width: 0 0 4px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-family: "DIN Next Rounded LT Pro Medium";
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 20px;
    margin: 0;
    outline: none;
    overflow: visible;
    padding: 13px 16px;
    text-align: center;
    text-transform: uppercase;
    touch-action: manipulation;
    transform: translateZ(0);
    transition: filter 0.2s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    width: 100%;
    letter-spacing: 1.5px;
  }
  
  .button-19:after {
    background-clip: padding-box;
    background-color: #55e208;
    border: solid transparent;
    border-radius: 16px;
    border-width: 0 0 4px;
    bottom: -4px;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
  
  .button-19:main,
  .button-19:focus {
    user-select: auto;
  }
  
  .button-19:disabled {
    cursor: auto;
  }
  
  .button-19:active {
    border-width: 4px 0 0;
    background: none;
  }
/* radio button end */